import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { appHistory } from './store/index';
import './theme/Global.scss';
import AppLayout from './layout/AppLayout';
import PrivateRoute from './utils/PrivateRoute';

import './i18n';

const ForgetPassword = lazy(() => import('./views/auth/forget-password/ForgetPassword'));
const Homepage = lazy(() => import('./views/homepage/Homepage'));
const ProductCare = lazy(() => import('./views/product-care/ProductCare'));
const ProductList = lazy(() => import('./components/product-list/ProductList'));
const ProductDetail = lazy(() => import('./views/product-detail/ProductDetail'));
const TermsConditions = lazy(() => import('./views/staticPages/terms-conditions/TermsConditions'));
const ShippingReturns = lazy(() => import('./views/staticPages/ShippingReturns'));
const PrivacyPolicy = lazy(() => import('./views/staticPages/PrivacyPolicy'));
const Cart = lazy(() => import('./views/cart/Cart'));
const CartCheckout = lazy(() => import('./views/checkout/checkout-cart/CartCheckout'));
const CartPayment = lazy(() => import('./views/checkout/checkout-payment/CartPayment'));
const Wishlist = lazy(() => import('./views/wishlist/Wishlist'));
const MyOrders = lazy(() => import('./views/accountPages/my-orders/MyOrders'));
const OrderDetails = lazy(() => import('./views/accountPages/my-orders/OrderDetails'));
const AddressList = lazy(() => import('./views/accountPages/address-list/AddressList'));
const AccountSettings = lazy(() => import('./views/accountPages/account-settings/AccountSettings'));
const ManageSubscription = lazy(() => import('./views/accountPages/account-settings/ManageNewsletter'));
const EditAccount = lazy(() => import('./views/accountPages/account-settings/EditAccount'));
const AboutPage = lazy(() => import('./views/staticPages/about-us/AboutUs'));
const Faq = lazy(() => import('./views/staticPages/Faq'));
function App() {
	return (
		<Router history={appHistory}>
			<AppLayout>
				<Suspense fallback={<span>Loading...</span>}>
					<Switch>
						<Route exact path="/" component={Homepage} />

						{/* Header Links */}
						<Route path="/forgot-password" component={ForgetPassword} />

						<Route exact path="/product-list" component={ProductList}

							key={Math.floor(Math.random() * 10 + 1)}
						/>

						<Route exact path="/search-list" component={ProductList}

							key={Math.floor(Math.random() * 10 + 1)}
						/>
						<Route
							exact
							path="/product-detail/:shortName"
							component={ProductDetail}
							key={Math.floor(Math.random() * 10 + 1)}
						/>
						<Route exact path="/view-cart" component={Cart} />
						<PrivateRoute exact path="/checkout-cart" component={CartCheckout} />
						<PrivateRoute exact path="/payment-cart" component={CartPayment} />
						<PrivateRoute exact path="/payment-cart/:shippingAddress" component={CartPayment} />

						<PrivateRoute exact path="/wishlist" component={Wishlist} />
						<PrivateRoute exact path="/my-orders" component={MyOrders} />
						<PrivateRoute exact path="/order-details/:orderId" component={OrderDetails} />
						<PrivateRoute exact path="/addresses" component={AddressList} />
						<PrivateRoute exact path="/account-setting" component={AccountSettings} />
						<PrivateRoute exact path="/manage-subscription" component={ManageSubscription} />
						<PrivateRoute exact path="/edit-account" component={EditAccount} />

						{/* Footer Links */}
						<Route exact path="/about-us" component={AboutPage} />
						<Route exact path="/faq" component={Faq} />
						<Route exact path="/product-care" component={ProductCare} />
						<Route exact path="/terms-conditions" component={TermsConditions} />
						<Route exact path="/shipping-returns" component={ShippingReturns} />
						<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					</Switch>
				</Suspense>
			</AppLayout>
		</Router>
	);
}

export default App;
