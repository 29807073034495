import React, { useEffect, useRef } from 'react';
import { Toast } from 'bootstrap';

import clsx from 'clsx';
import styles from './Toast.module.scss';

function ToastMsg(props) {
	const toastRef = useRef(null);
	const { alertMsg = {} } = props;

	useEffect(() => {
		if (alertMsg?.message) {
			onShow();
		}
	}, [alertMsg]);

	const onClose = () => {
		const bsToast = new Toast(toastRef.current);

		bsToast.hide();
	};
	const onShow = () => {
		const bsToast = new Toast(toastRef.current);

		bsToast.show();
	};
	return (
		<div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
			<div
				role="alert"
				aria-live="assertive"
				aria-atomic="true"
				className="toast"
				data-autohide="true"
				data-delay="10000"
				ref={toastRef}
			>
				<div className={clsx(styles.toastHeader, 'toast-header')}>
					<strong />
					{/* <strong className="mr-auto">Bootstrap</strong>
				<small>11 mins ago</small> */}
					<button type="button" className={clsx(styles.closeBtn, 'ml-2 mb-1 close')} onClick={onClose}>
						<span aria-hidden="true" style={{ fontSize: 18 }}>
							&times;
						</span>
					</button>
				</div>
				<div className={clsx(styles.toastBody, 'toast-body')}>
					<i className={clsx(styles.alertIcon, Toast.success, 'bi bi-exclamation-circle-fill')} />{' '}
					{alertMsg.message}
				</div>
			</div>
		</div>
	);
}

export default ToastMsg;
