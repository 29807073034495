import moment from 'moment';

export function isEmpty(obj) {
	let isempty = false;
	const type = typeof obj;

	isempty = isempty || !obj;
	isempty = isempty || type === 'undefined'; // if it is undefined
	isempty = isempty || obj === null; // if it is null
	isempty = isempty || (type === 'string' && obj.trim() === ''); // if the string is empty
	isempty = isempty || obj === false || obj === 0; // if boolean value returns false
	isempty = isempty || (Array.isArray(obj) && obj.length === 0); // if array is empty
	isempty = isempty || (type === 'object' && Object.keys(obj).length === 0); // if object is empty

	return isempty;
}

export function getDateBefore(days, format) {
	return moment().subtract(days, 'd').format(format);
}

export function getCurrentDateInFormat(format) {
	return moment().format(format);
}

export function getDateFormat(date) {
	return moment(new Date(date)).format('LL');
}

export function compareUserData(oldData, newData, result) {
	Object.keys(oldData).forEach(function (k) {
		if (typeof oldData[k] !== 'object') {
			if (oldData[k] != newData[k]) this.push({ old: oldData[k], new: newData[k] });
		} else {
			compareUserData(oldData[k], newData[k], this);
		}
	}, result);

	return result;
}

export function sortObj(data, sortKey) {
	if (isEmpty(data)) {
		return [];
	}
	const res = data.sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : b[sortKey] > a[sortKey] ? -1 : 0));

	return res;
}

export function sortImagePrimaryObj(data, sortKey) {

	
	if (isEmpty(data)) {
		return [];
	}
	
	const res = data.sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : b[sortKey] > a[sortKey] ? -1 : 0));
	return res.reverse();
}
