import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAlertMsg } from 'store/common/commonSlice';
import * as requestFromServer from './shoppingCrud';

export const addToCartApi = createAsyncThunk('ncr2/addToCartApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.addToCart(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: 'Item updated in cart successfully',
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is error in login');
	} catch (error) {
		error.clientMessage = 'Error login ';
		return thunkApi.rejectWithValue({ error });
	}
});

export const getCartItemsApi = createAsyncThunk('ncr2/getCartItemsApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.getCartItems(params);
		if (response.data) {
			const { data } = response;

			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});

export const removeFromCartApi = createAsyncThunk('ncr2/removeFromCartApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.removeFromCart(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: 'Item from cart is removed successfully',
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});

export const clearCartApi = createAsyncThunk('ncr2/clearCartApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.clearCart(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: 'Cart cleared successfully',
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is error in login');
	} catch (error) {
		error.clientMessage = 'Error login ';
		return thunkApi.rejectWithValue({ error });
	}
});

export const addCustomerAddressApi = createAsyncThunk('ncr2/addCustomerAddressApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.addCustomerAddress(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: 'Address added successfully',
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});

export const confirmInventoryApi = createAsyncThunk('ncr2/confirmInventoryApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.confirmInventory(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: 'Order placed successfully',
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
		
	}
});

export const placeOrderApi = createAsyncThunk('ncr2/placeOrderApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.placeOrder(params);
		if (response.data) {
			const { data } = response;

			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});



export const initiateCartPaymentApi = createAsyncThunk('ncr2/initiateCartPaymentApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.initiateCartPayment(params);
		if (response.data) {
			const { data } = response;

			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});

export const addItemsToWishlistApi = createAsyncThunk('ncr2/addItemsToWishlistApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.addToWishlist(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: 'Item added to Wishlistcart successfully',
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});


export const removeFromWishlistApi = createAsyncThunk('ncr2/removeFromWishlistApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.removeFromWishlist(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: 'Item from Wishlistcart is removed successfully',
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});

export const getCustomerWishlistProductsApi = createAsyncThunk(
	'ncr2/getCustomerWishlistProductsApi',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.getCustomerWishlistProducts(params);
			if (response.data) {
				const { data } = response;

				return data;
			}
			return thunkApi.rejectWithValue('There is no data available');
		} catch (error) {
			error.clientMessage = 'Something went wrong';
			return thunkApi.rejectWithValue({ error });
		}
	}
);


export const getCustomerOrdersApi = createAsyncThunk(
	'ncr2/getOrderSummary',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.getCustomerOrdersApi(params);
			if (response.data) {
				const { data } = response;

				return data;
			}
			return thunkApi.rejectWithValue('There is no data available');
		} catch (error) {
			error.clientMessage = 'Something went wrong';
			return thunkApi.rejectWithValue({ error });
		}
	}
);
export const getProductsByIdApi = createAsyncThunk('ncr2/getProductsByIdApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.getProductsById(params);
		if (response.data) {
			const { data } = response;

			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});

export const getOrderDetailsByIdApi = createAsyncThunk('ncr2/getOrderDetailsByIdApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.getOrderDetailsById(params);
		if (response.data) {
			const { data } = response;

			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});

export const mapAddressToCartApi = createAsyncThunk('ncr2/mapAddressToCartApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.mapAddressToCart(params);
		if (response.data) {
			thunkApi.dispatch(
				setAlertMsg({
					message: "Address Changed Successfully!",
					type: 'success'
				})
			);
			const { data } = response;
			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});
export const addCouponCodeToCartApi = createAsyncThunk('ncr2/addCouponCodeToCartApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.addCouponCodeToCart(params);
		if (response.data) {
			const { data } = response;

			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});
export const addMessageToCartApi = createAsyncThunk('ncr2/addMessageToCartApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.addMessageToCart(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: "Cart Message Added!",
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});




export const removeCouponCodeFromCartApi = createAsyncThunk('ncr2/removeCouponCodeFromCartApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.removeCouponCodeFromCart(params);
		if (response.data) {
			const { data } = response;

			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Something went wrong';
		return thunkApi.rejectWithValue({ error });
	}
});
