import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'utils/common';
import { setBackdrop } from './commonSlice';
import * as requestFromServer from './authCrud';
import * as requestShoppingFromServer from '../shopping/shoppingCrud';
export const getPageMetaDataByStoreApi = createAsyncThunk(
	'ncr2/getPageMetaDataByStoreApi',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.getPageMetaDataByStore(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('There is error in getting meta data by store id');
		} catch (error) {
			error.clientMessage = 'Error getting meta data by store id ';
			return thunkApi.rejectWithValue({ error });
		}
	}
);

export const getHomepageApi = createAsyncThunk('ncr2/getHomepageApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.getHomepageApi(params);
		if (response.data) {
			const { data } = response;
			// thunkApi.dispatch(getObjectDetails())
			// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
			// thunkApi.dispatch(getObjectDetails(firstParent?._id))
			return data;
		}
		return thunkApi.rejectWithValue('There is error in getting homepage');
	} catch (error) {
		error.clientMessage = 'Error getting homepage ';
		return thunkApi.rejectWithValue({ error });
	}
});
export const getPageSectionBySectionKeyApi = createAsyncThunk(
	'ncr2/getPageSectionBySectionKeyApi',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.getPageSectionBySectionKey(params);
			if (response.data) {
				thunkApi.dispatch(setBackdrop(true));
				const { data } = response;
				thunkApi.dispatch(setBackdrop(false));

				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return { data, sectionKey: params.sectionKey };
			}
			return thunkApi.rejectWithValue('There is error in login');
		} catch (error) {
			error.clientMessage = 'Error login ';
			return thunkApi.rejectWithValue({ error });
		}
	}
);
export const getStoreCategoriesApi = createAsyncThunk('ncr2/getStoreCategoriesApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.getStoreCategory(params);
		if (response.data) {
			const { data } = response;
			const categories = Array.isArray(data) && data.find(ele => !!ele.Categories)?.Categories;
			return categories;
		}
		return thunkApi.rejectWithValue('There is error in getting store category');
	} catch (error) {
		error.clientMessage = 'Error in getting store category ';
		return thunkApi.rejectWithValue({ error });
	}
});


export const getUserAddressListApi = createAsyncThunk('ncr2/getUserAddressListApi', async (params, thunkApi) => {
	try {
		const response = await requestShoppingFromServer.getUserAddressList(params);
		if (response.data) {
			const { data } = response;
			// =const addresses = Array.isArray(data) && data.find(ele => !!ele.Categories)?.Categories;
			return data;
		}
		return thunkApi.rejectWithValue('There is error in getting addresses');
	} catch (error) {
		error.clientMessage = 'Error in getting addresses';
		return thunkApi.rejectWithValue({ error });
	}
});


export const removeUserAddressApi = createAsyncThunk('ncr2/removeUserAddressApi', async (params, thunkApi) => {
	try {
		const response = await requestShoppingFromServer.removeUserAddressApi(params);
		if (response.data) {
			const { data } = response;
			// =const addresses = Array.isArray(data) && data.find(ele => !!ele.Categories)?.Categories;
			return data;
		}
		return thunkApi.rejectWithValue('There is error in getting addresses');
	} catch (error) {
		error.clientMessage = 'Error in getting addresses';
		return thunkApi.rejectWithValue({ error });
	}
});