import React from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { registerUserForNewsLetterApi } from 'store/common/authApi';
import styles from './NewsLetterSection.module.scss';

const initialValues = {
	email: ''
};

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Please Enter Valid Email Id').required('Please Enter Valid Email Id')
});

export default function NewsLetterSection() {
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, formikProps) => {
			formikProps.setSubmitting(true);
			dispatch(registerUserForNewsLetterApi(values)).then(data => {
				formikProps.resetForm();
			});
		}
	});

	return (
		<>
			<section className={styles.newsLetterSection}>
				<div className={clsx(styles.myContainer, 'myContainer')}>
					<div className={styles.content}>
						<h3 className={clsx(styles.sectionHead, 'sectionHead')}>Sign Up for Our Newsletter</h3>
						<p className={styles.subHead}>
							Sign up for exciting news and offers, experience the world of Janavi India online.
						</p>

						<form className={clsx(styles.newsLetterForm, 'form subscribe')} onSubmit={formik.handleSubmit}>
							<div className={styles.field}>
								<div className={styles.control}>
									<label className="w-100">
										<input
											name="email"
											type="email"
											id="newsletter"
											placeholder="Email Address"
											className={styles.newsLetterInput}
											{...formik.getFieldProps('email')}
										/>
										{formik.touched.email && formik.errors.email && (
											<div className={styles.errorMsg}>{formik.errors.email}</div>
										)}
									</label>
								</div>
							</div>
							<div className="actions">
								<button
									className={clsx(styles.subscribeBtn, 'action subscribe primary')}
									title="Submit"
									type="submit"
									aria-label="Subscribe"
									id="button_footer_subscribe"
								>
									<span>Subscribe</span>
								</button>
							</div>
							<div className={styles.response} />
						</form>
					</div>
				</div>
			</section>
		</>
	);
}
