import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import models from 'utils/constants/models';
import queryString from 'query-string';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { setAuthModal, setPageSectionData } from 'store/common/commonSlice';
import { searchProductApi } from 'store/product/productsApi';
import { unwrapResult } from '@reduxjs/toolkit';
import _ from 'lodash';
import AUTH_MODAL from 'utils/constants/authModalConst';
import { isEmpty } from 'utils/common';
import CartRemoveConfirm from 'layout/components/cart-popup/CartConfirm';

import CartPopUp from '../components/cart-popup/CartPopup';
import { getCustomerWishlistProductsApi, removeFromCartApi } from 'store/shopping/shoppingApi';
import styles from './AppHeader.module.scss';
import { left, right } from '@popperjs/core';
import { getPageMetaDataByStoreApi, getPageSectionBySectionKeyApi } from 'store/common/commonApi';

const womenFeatured = [
	{
		path: '',
		imgPath: 'https://janaviindia.com/media/catalog/category/kieraNav_1.jpg',
		altName: 'Janavi India',
		name: 'Janavi India X Kiera Chaplin'
	},
	{
		path: '',
		imgPath: 'https://janaviindia.com/media/catalog/category/womenRitzSmall.jpg',
		altName: 'Janavi India',
		name: 'Ritz'
	}
];
let primarySubCat = [];


function AppHeader(props) {
	const history = useHistory();
	const { isScrolled, headerConfig = {} } = props;
	const [optLoading, setOptLoading] = useState(false);
	const [searchActivated, setSearchActivated] = useState(false);
	const [searchList, setSearchList] = useState([]);
	const [searchIcon, setSearchIcon] = useState(false);
	const [searchValue, setSearchValue] = useState(undefined);
	const [itemForAction, setItemForAction] = useState({});
	const [activeLink, setActiveLink] = useState(null);
	const [loggedIn, setLoggedIn] = useState(false);
	const dispatch = useDispatch();
	const queryParams = queryString.parse(history?.location?.search);

	const goToProduct = (item) => {
		setSearchActivated(false);
		let clearTxt = "";
		setSearchValue(clearTxt);
		history.push('/product-detail/' + item.productId);

	}
	const sortArr = (arr) => {
		let sortedArr = arr;

		// if(arr.length){
		// 	sortedArr = arr.sort(function (a, b) {
		// 		return a.subCategoryId - b.subCategoryId || a.subCategoryName.localeCompare(b.subCategoryName);
		// 	});

		// }

		return sortArr;
	}
	useEffect(() => {
		// getWishlistProducts();
		const { referenceId, parentId } = queryParams || {};
		setActiveLink(parentId || referenceId || null);
	}, [queryParams]);

	useEffect(() => {
		getWishlistProducts();
	}, []);

	// const user = JSON.parse('userName');
	const user = localStorage.getItem('userProfile');
	const userName = JSON.parse(user);
	const getWishlistProducts = () => {
		const params = {
			otherReferenceId: '',
			pageNo: 0,
			pageSize: 10,
			sortOption: 'Desc',
			sortOrder: 'ASC'
		};
		dispatch(getCustomerWishlistProductsApi(params));
	};
	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			setSearchValue(event.target.value);
			setSearchActivated(false);
			history.push('/search-list?type=search&text=' + event.target.value);
			setSearchActivated(false);

		}
	}
	const onProductSearch = value => {
		const params = {
			filter: 'TextSearch',
			pageSize: 20,
			pageNo: 0,
			sendAllProducts: true,
			otherReferenceId: '',
			categoryId: '',
			sortOption: 'productName',
			sortProduct: 'Desc',
			storeId: window?.env?.STORE_ID,
			searchString: value
		};
		dispatch(searchProductApi(params))
			.then(unwrapResult)
			.then(data => {
				const { products = [] } = data;
				setSearchList(products);
				setSearchActivated(true);
			})

			.catch(err => {
				console.error('err is', err);
				setOptLoading(false);
			});
	};

	const { cartItems = {} } = useSelector(({ shopping }) => shopping);
	const { wishlistProducts = [] } = useSelector(state => state.shopping);
	const { pageMetaData = {}, pageSectionData = {} } = useSelector(({ common }) => common);

	const listSection =
		(Array.isArray(pageMetaData?.listSection) &&
			pageMetaData?.listSection.find(sec => sec.verticalDisplayOrder === 1)) ||
		{};
	const sectionData = (pageSectionData && pageSectionData[listSection?.sectionKey]) || {};

	function handleSearch(event) {
		if (event.target.value !== '' || event.target.value !== null) {
			if (event.target.value.length >= 3) {
				searchProductDebounce(event.target.value);
			} else {
				setSearchList([]);
				setSearchActivated(false);
			}
		}
		setSearchValue(event.target.value);
	}

	const onRemoveItemAgreed = () => {
		const param = {
			...models.cartItem,
			cartId: isEmpty(localStorage.getItem('cartId')) ? '' : localStorage.getItem('cartId'),
			// productId: product.productId,
			quantity: itemForAction?.quantity,
			productId: itemForAction?.productDto?.productId
		};
		dispatch(removeFromCartApi(param));
	};

	const isLinkActive = categoryId => {
		return referenceId === categoryId || parentId === categoryId;
	};

	const searchProductDebounce = useCallback(_.debounce(onProductSearch, 300), []);
	useEffect(() => {
		getHomePageData();
		//	context.setHeaderConfig({...context.headerConfig , showTransparent :true})
	}, []);
	const wishProdLength = Array.isArray(wishlistProducts) && wishlistProducts.length;
	const getHomePageData = async () => {
		const param = {
			pageType: 'HomePage',
			sectionKey: ''
			// sectionKey: storeKey,
		};
		try {
			let metaDataRes = await dispatch(getPageMetaDataByStoreApi(param));
			metaDataRes = unwrapResult(metaDataRes);
			const { listSection = [] } = metaDataRes;
			const metaSections = {};
			await Promise.all(
				listSection.map(async section => {
					let metaData = await dispatch(
						getPageSectionBySectionKeyApi({ pageType: 'HomePage', sectionKey: section.sectionKey })
					);
					metaData = unwrapResult(metaData);
					// metaSections[metaData]

					metaSections[metaData.sectionKey] = metaData.data;
				})
			);
			dispatch(setPageSectionData(metaSections));
		} catch (err) {
			console.log('err is', err);
		}
	};

	const removeDups = (arr) => {

		if (arr?.length) {
			return arr.filter((thing, index) => {
				const _thing = JSON.stringify(thing);
				return index == arr.findIndex(obj => {
					return JSON.stringify(obj) === _thing;
				});
			});
		} else {
			return [];
		}

	}
    useEffect(() => {
        history.listen((location, action) => {
            console.log("on route change");
            
            setSearchActivated(false);
        });
    }, []);

	// Logout
	const onLogout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('loginId');
		localStorage.removeItem('userProfile');
		localStorage.clear();
		history.push('/');
		window.location.reload();
	};
	return (

		<header
			className={clsx(styles.mainHeader, {
				// [styles.mainHeaderScrolled]: true,

				[styles.mainHeaderScrolled]: isScrolled,
				[styles.darkBackground]: !headerConfig.showTransparent,
				[styles.transBackground]: headerConfig.showTransparent
			})}
		>
			<div className={clsx(styles.header_top, 'd-none')}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<div className={clsx(styles.header_top_msg, 'text-center')}>
								<p>
									Delivery delays may occur given the current government restrictions. We apologise
									for any inconvenience caused.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.header_bottom}>
				<div className={clsx(styles.myContainer, 'container-fluid')}>
					<div className={clsx(styles.headerWrapper, { [styles.headerWrapperScrolled]: isScrolled })}>
						<div className={clsx('col-md-3')}>
							<div className={clsx(styles.navSection, { [styles.navSectionScrolled]: isScrolled })}>
								<div
									className={clsx(styles.navSectionItems, {
										[styles.navSectionItemsScrolled]: isScrolled
									})}
								>
									<div className={styles.mobileSunCrossWrap}>
										<div className={styles.sunLogo}>
											<Link to="/">
												<img
													className={styles.sunLogoImg}
													src='../img/logo/sunLogo.png'


													alt="JanaviIndia"
												/>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={clsx('col-md-6')}>
							<div
								className={clsx(styles.middleContainer, 'w-100', {
									[styles.middleContainerScrolled]: isScrolled
								})}
							>
								<div className={clsx(styles.logoBg, { [styles.logoBgScrolled]: isScrolled })}>
									<Link to="/" className={styles.linkItem}>
										<img
											className={styles.logoMain}
											src="../img/logo/logo.png"
											alt="JanaviIndia"
											width="170"
										/>
									</Link>
								</div>
								<div className={styles.menuContainer}>
									<nav className={styles.navigationCustom}>
										<ul className={styles.navigationCustomUL}>
											<li className={clsx(styles.categoryItem)}>
												<Link
													to={`/product-list?type=NewArrival&referenceId=${'NewArrival'}`}
													className={clsx(styles.linkItem, {
														[styles.active]: activeLink === String('NewArrival')
													})}
												>
													What's New
												</Link>
											</li>


											{removeDups(sectionData?.Categories).map(category => {
												const {
													categoryName = '',
													categoryId = '',
													subCategory = []
												} = category || {};
												primarySubCat = [];

												return (
													<li className={clsx(styles.categoryItem)}>
														<Link
															to={`/product-list?type=category&referenceId=${categoryId}`}
															className={clsx(styles.linkItem, {
																[styles.active]: activeLink === String(categoryId)
															})}
															onClick={() => setActiveLink(categoryId)}
														>
															{categoryName}
														</Link>
														<div
															className={clsx(
																styles.megaDropDownMenu,
																'dropdown-menu-items single-drop'
															)}
														>
															<div className="container-fluid">
																<div className="row">
																	<div className="col-md-6">
																		<ul className={styles.subMenuItems}>
																			{subCategory.map(item => {


																				// if (item?.attributes == 'isFeatured:1')
																				//	primarySubCat.push(item);

																				if (item.hasOwnProperty('attributes')) {

																					if (item?.attributes) {
																						if (item?.attributes !== 'isFeatured:1') {
																							if (!JSON.parse(item?.attributes, null, 2).hasOwnProperty('featuredMediaUrls')) {
																								if (!JSON.parse(item?.attributes, null, 2).hasOwnProperty('isFeatured')) {

																									if (!JSON.parse(item?.attributes, null, 2).hasOwnProperty('featuredMediaUrls')) {

																										primarySubCat.push(item)
																									}
																								}
																							}
																						}
																					}
																				}

																				return (
																					<>
																						<li className={styles.navLinkList}>
																							<NavLink
																								to={`/product-list?type=subCategory&referenceId=${item?.subCategoryId}&parentId=${categoryId}`}
																								className={styles.navLink1}
																								onClick={() =>
																									setActiveLink(categoryId)
																								}
																							>
																								<span
																									className={styles.navLink2}
																								>
																									{item?.subCategoryName}
																								</span>
																							</NavLink>
																						</li>


																					</>
																				)
																			})}
																		</ul>
																	</div>
																	<div className="col-md-6 d-flex flex-row justify-content-center align-items-center">
																		{primarySubCat.map(item1 => (
																			<div className={styles.featuredContainer}>
																				<div
																					className={styles.featuredImageCard}
																				>

																					{/* <pre style={styles.pre}>
																						{item1?.attributes ? !JSON.parse(item1?.attributes).hasOwnProperty('featuredMediaUrls') ? JSON.parse(JSON.parse(item1?.attributes)).featuredMediaUrls : null : null}
																					</pre> */}
																					<NavLink
																						to={`/product-list?type=subCategory&referenceId=${item1?.subCategoryId}&parentId=${categoryId}`}
																						className={clsx(
																							styles.navLink1,
																							'navLink'
																						)}
																					>
																						<a
																							href="javascript:void(0)"
																							className={clsx(
																								styles.featuredImageLink
																							)}
																						>
																							<img
																								className={
																									styles.featuredImage
																								}
																								src={item1?.attributes ? !JSON.parse(item1?.attributes).hasOwnProperty('featuredMediaUrls') ? JSON.parse(JSON.parse(item1?.attributes)).featuredMediaUrls : null : null}
																								alt={item1?.subCatMedia?.mediaTags}
																							/>
																							<p className="m-0">
																								{item1?.subCategoryName}
																							</p>
																						</a>
																					</NavLink>
																				</div>
																			</div>
																		))}
																	</div>
																</div>
															</div>
														</div>
													</li>
												);
											})}
										</ul>
									</nav>
								</div>
							</div>
						</div>
						<div className={clsx('col-md-3')}>
							<div className={clsx(styles.headerActions)}>
								{searchIcon && (
									<>
										<div className={styles.searchBlock}>
											<input
												onKeyPress={event => handleKeyPress(event)}
												onChange={event => handleSearch(event)}
												// onBlur={event => setSearchActivated(false)}
												value={searchValue}
												id="search"
												type="text"
												name="Search"
												placeholder="Search..."
												className={styles.inputArea}
												maxLength={128}
												aria-autocomplete="both"
												autoComplete="off"
												autoFocus
											/>

											{searchActivated && (
												<div>
													<div
														className={styles.searchAutocompleteBackdrop}
														onClick={() => setSearchIcon(false)}
													>
													</div>

													<div id="search_autocomplete" className={styles.searchAutoComplete}>


														{searchList.length ?
															<ul className={styles.searchList}>
																{searchList.map(item => (
																	<a onClick={() => goToProduct(item)}>

																		<li className={styles.searchListItem} >
																			{item?.productName}
																		</li>

																	</a>
																))}
															</ul>
															: <ul className={styles.searchList}>
																<li className={styles.searchListItem} >
																	No Results
																</li>
															</ul>}
													</div>
												</div>
											)}
										</div>
									</>
								)}
								<div className={styles.headerActionIcons}>
									<ul className={styles.iconList}>
										<li className={styles.iconListItem}>
											<a className={styles.actionLink}>
												<img
													className={styles.actionImg}
													src="../img/headerActions/search.svg"
													title="Search"
													onClick={() => setSearchIcon(!searchIcon)}
													alt="Search"
												/>
											</a>
										</li>
										<li className={clsx(styles.iconListItem, styles.wishlistContainer)}>
											<Link to="/wishlist">
												<a className={styles.actionLink}>
													<img
														className={styles.actionImg}
														src="../img/headerActions/heart.svg"
														title="Wishlist"
														alt="Wishlist"
													/>
													<span className={styles.cartCounter}>
														<span className={styles.cartCounterNumber}>
															{!isEmpty(wishlistProducts) ? wishProdLength : 0}
														</span>
													</span>
												</a>
											</Link>
										</li>
										<li className={clsx(styles.iconListItem)}>
											{user ? (
												<>
													<Link to="" className={clsx(styles.actionLink, styles.account)}>
														<img
															className={styles.actionImg}
															src="../img/headerActions/user.svg"
															title="My Account"
															alt="My Account"
														/>

														{userName && (
															<span className={styles.greetingMsg}>
																{' '}
																Hi {userName?.userFirstName}!
															</span>
														)}
													</Link>
													<ul className={styles.accountMenuBox}>
														<li className={styles.accountMenuBoxItem}>
															<Link
																to={`account-setting`}
																className={styles.accountMenuBoxItemTitle}
															>
																My Account
															</Link>
														</li>
														<li className={styles.accountMenuBoxItem}>
															<Link
																to={`my-orders`}
																className={styles.accountMenuBoxItemTitle}
															>
																My Order
															</Link>
														</li>
														<li className={styles.accountMenuBoxItem}>
															<Link
																to=""
																onClick={onLogout}
																className={styles.accountMenuBoxItemTitle}
															>
																Sign Out
															</Link>
														</li>
													</ul>
												</>
											) : (
												<Link to="">
													<a
														className={clsx(styles.actionLink, styles.account)}
														onClick={() => dispatch(setAuthModal(AUTH_MODAL.LOGIN))}
													>
														<img
															className={styles.actionImg}
															src="../img/headerActions/user.svg"
															title="Login"
															alt="Login"
														/>
													</a>
												</Link>
											)}
										</li>
										<li className={styles.iconListItem}>
											<a
												className={clsx(styles.actionLink, styles.cartIcon)}
												href="javascript:void();"
											>
												<img
													className={styles.actionImg}
													src="../img/headerActions/shopping-bag.svg"
													title="My Cart"
													alt="My Cart"
												/>
												<span className={styles.cartCounter}>
													<span className={styles.cartCounterNumber}>
														{!isEmpty(cartItems) ? cartItems.countOfProducts : 0}
													</span>
												</span>
											</a>
											<CartPopUp cartItems={cartItems} setItemForAction={setItemForAction} />
											<CartRemoveConfirm onRemoveItemAgreed={onRemoveItemAgreed} />
										</li>
										<li className={clsx(styles.iconListItem, 'd-none')}>
											<div className={clsx(styles.myLanguageDropdown, 'btn-group')}>
												<button
													type="button"
													className={clsx(
														styles.myDropdownBtn,
														'btn btn-secondary dropdown-toggle'
													)}
													data-bs-toggle="dropdown"
													data-bs-display="static"
													aria-expanded="false"
												>
													<div className="container-fluid">
														<div className="row">
															<div className="col-6 p-0 d-flex flex-row justify-content-center align-items-center">
																<img
																	className={styles.actionImg2}
																	src="../img/countryFlags/usaFlag.svg"
																	title="English"
																	alt="English"
																/>
															</div>
															<div className="col-6 d-flex flex-row justify-content-center align-items-center">
																<p className="m-0 text-white">EN</p>
															</div>
														</div>
													</div>
												</button>
												<ul
													className={clsx(
														styles.myDropdownMenu,
														'dropdown-menu dropdown-menu-end dropdown-menu-lg-start'
													)}
												>
													<li>
														<button
															className={clsx(styles.dropdownItem, 'dropdown-item')}
															type="button"
														>
															<div className="container-fluid">
																<div className="row">
																	<div className="col-6 d-flex flex-row justify-content-center align-items-center">
																		<img
																			className={styles.actionImg2}
																			src="../img/countryFlags/usaFlag.svg"
																			title="English"
																			alt="English"
																		/>
																	</div>
																	<div className="col-6 d-flex flex-row justify-content-center align-items-center">
																		<p className="m-0">EN</p>
																	</div>
																</div>
															</div>
														</button>
													</li>
													<li>
														<button
															className={clsx(styles.dropdownItem, 'dropdown-item')}
															type="button"
														>
															<div className="container-fluid">
																<div className="row">
																	<div className="col-6 d-flex flex-row justify-content-center align-items-center">
																		<img
																			className={styles.actionImg2}
																			src="../img/countryFlags/spainFlag.svg"
																			title="Spanish"
																			alt="Spanish"
																		/>
																	</div>
																	<div className="col-6 d-flex flex-row justify-content-center align-items-center">
																		<p className="m-0">ES</p>
																	</div>
																</div>
															</div>
														</button>
													</li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
export default AppHeader;


