import { createAsyncThunk } from '@reduxjs/toolkit';
import * as requestFromServer from './productsCrud';

export const searchProductApi = createAsyncThunk('ncr2/searchProductApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.findProducts(params);
		if (response.data) {
			const { data } = response;
			// thunkApi.dispatch(getObjectDetails())
			// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
			// thunkApi.dispatch(getObjectDetails(firstParent?._id))
			return data;
		}
		return thunkApi.rejectWithValue('There is no data available');
	} catch (error) {
		error.clientMessage = 'Error finding product using product tag';
		return thunkApi.rejectWithValue({ error });
	}
});

// TODO: remove when new api integraition done
export const abd = 'abd';
