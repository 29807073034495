import { createSlice } from '@reduxjs/toolkit';

const initialProductsState = {
	listLoading: false,
	totalCount: 0,
	entities: null,
	actionsLoading: false,
	error: ''
};

const productsSlice = createSlice({
	name: 'products',
	initialState: initialProductsState,
	reducers: {
		// findProducts
		productsFetched: (state, action) => {
			const { totalCount, entities } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = entities;
			state.totalCount = totalCount;
		}
	}
});

export default productsSlice.reducer;
