import React, { useEffect, useContext, useState } from 'react';
import clsx from 'clsx';
import { forgotYourPasswordAPi, passwordResetApi, sendOtpApi, verifyOtpApi } from 'store/common/authApi';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { setAuthModal } from 'store/common/commonSlice';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import SocialSection from '../../../layout/components/social-section/SocialSection';
import NewsLetterSection from '../../../layout/components/newsletter-section/NewsLetterSection';
import styles from './ForgetPassword.module.scss';
import { verifyOtp } from 'store/common/authCrud';
import LayoutContext from 'context/LayoutContext';


export default function ForgetPassword() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [isOtpSent, setIsOtpSent] = useState(false);
	const [phoneNo, setPhoneNo] = useState(null);
	const layoutContext = useContext(LayoutContext);

	// Header background color settings
	useEffect(() => {
		layoutContext.setHeaderConfig({ ...layoutContext.headerConfig, showTransparent: false });
	}, []);

	const history = useHistory();
	//    controlling formik here
	const initialValues = {
		// password: '',
		email: '',
		// confirmPassword: ''
	};
	const forgotPasswordSchema = Yup.object().shape({
		// password: Yup.string(),
		email: Yup.string().email().required(),
		// confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
	});


	const formik = useFormik({
		initialValues,
		validationSchema: forgotPasswordSchema,
		onSubmit: (values, formikProps) => {
			setLoading(true);
			formikProps.setSubmitting(true);
			dispatch(
				sendOtpApi({
					phoneNumber: values.email.trim(),
					password: "",
					storeId: localStorage.getItem('storeId')
				})).then(() => {
					setIsOtpSent(true);
					setPhoneNo(values.email);
					return;
				}).catch(() => {
					setLoading(false);
					formikProps.setSubmitting(false);
					formikProps.setStatus('Invalid mobile number');
					//history.push('/');
				});
		}
	});

	const initialValues1 = {
		otp: '',
		password: '',
		confirmPassword: ''
	};
	const forgotPasswordSchema1 = Yup.object().shape({
		otp: Yup.number().required(),
		password: Yup.string().required(),
		confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
	});
	const formik1 = useFormik({
		initialValues: initialValues1,
		// enableReinitialize: true,
		validationSchema: forgotPasswordSchema1,
		onSubmit: (values, formikProps) => {
			setLoading(true);
			formikProps.setSubmitting(true);
			dispatch(
				verifyOtpApi({
					password: values.password,
					phoneNumber: phoneNo,
					token: values.otp,
					storeId: localStorage.getItem('storeId')
				}))
				.then(unwrapResult)
				.then(data1 => {

					console.log(data1);
					dispatch(
						passwordResetApi({
							phoneNumber: phoneNo,
							password: values.password,
							storeId: localStorage.getItem('storeId'),
							token: data1
						}))
						.then(unwrapResult)
						.then(data => {

							if (data.loginStatus) {
								localStorage.setItem('token', data.accessToken);
								localStorage.setItem('loginToken', data.loginToken);
								localStorage.setItem(
									'userProfile',
									JSON.stringify({ userFirstName: data.userFirstName, userLastName: data.userLastName })
								);
								// alert("hi");
								window.location.href = "/";
								dispatch(setAuthModal(null));

							}
							formikProps.setSubmitting(false);
							history.push('/');
							setLoading(false);
							formikProps.setSubmitting(false);
						}).catch(() => {
							setLoading(false);
							formikProps.setSubmitting(false);
							formikProps.setStatus('Invalid otp or password');
							// localStorage.clear()
							// history.push('/')
						});


				}).catch(() => {
					setLoading(false);
					formikProps.setSubmitting(false);
					formikProps.setStatus('Invalid otp or password');
					// localStorage.clear()
					// history.push('/')
				});
		}
	});

	return (
		<>
			<section className={styles.ForgetPassword}>
				<div className={clsx(styles.myContainer, 'myContainer')}>
					<div className={styles.formPage}>
						<div className="customerLoginBlock">
							<div className={styles.customerLoginBlockInner}>



								{!isOtpSent ? (

									<>
										<div className={styles.blockTitleContainer}>
											{/* <h3 className={styles.blockTitle}>
										Please enter your email address below to receive a password reset link.
									</h3> */}

											<h3 className={styles.blockTitle}>Enter your Email Address.
											</h3>
										</div>


										<div className={styles.blockContentContainer}>
											<form className={clsx(styles.myForm)} onSubmit={formik.handleSubmit}>
												<fieldset className={styles.fieldset}>
													<div className={clsx(styles.emailField, styles.myFormFields)}>
														{/* <input
				name="email"
				placeholder="Email address"
				className={clsx(styles.myInputField, styles.emailInputField)}
				required
			/>
			<div className={styles.errorMsg}>This is a required field</div> */}
														<input
															name="email"
															placeholder="Email Address"
															className={clsx(styles.myInputField, styles.emailInputField)}
															required
															{...formik.getFieldProps('email')}
														/>
														{formik.touched.email && formik.errors.email && (
															<div className={styles.errorMsg}>{formik.errors.email}</div>
														)}
													</div>
													{/* <div className={clsx(styles.pwdField, styles.myFormFields)}>
			<input
				name="password"
				placeholder="Enter password"
				className={clsx(styles.myInputField, styles.pwdInputField)}
				required
				{...formik.getFieldProps('password')}
			/>
			{formik.touched.password && formik.errors.password && (
				<div className={styles.errorMsg}>{formik.errors.password}</div>
			)}
		</div>
		<div className={clsx(styles.pwdField, styles.myFormFields)}>
			<input
				name="confirmPassword"
				placeholder="Enter confirmPassword"
				className={clsx(styles.myInputField, styles.pwdInputField)}
				required
				{...formik.getFieldProps('confirmPassword')}
			/>
			{formik.touched.confirmPassword && formik.errors.confirmPassword && (
				<div className={styles.errorMsg}>
					{formik.errors.confirmPassword}
				</div>
			)}
		</div> */}
													{/* <div className={styles.captchaContainer}>
			<div className={styles.captchaContainerContent}>
				<img
					src="img/captcha/captcha.png"
					className={styles.captchaImg}
					alt="JanaviIndia"
				/>
				<button className={styles.reloadBtn}>Reload Captcha</button>
			</div>
		</div> */}
													<div className={styles.actionButtons}>
														<div className={styles.primary}>
															<button
																className={clsx(styles.primaryBtn, 'signIn')}
																name="sendBtn"
																type="submit"
															>
																{/* Reset My Password */}

																SEND OTP
															</button>
														</div>
													</div>
												</fieldset>
											</form>
										</div>
									</>
								) : (

									<>
										<div className={styles.blockTitleContainer}>
											{/* <h3 className={styles.blockTitle}>
										Please enter your email address below to receive a password reset link.
									</h3> */}

											<h3 className={styles.blockTitle}>Enter OTP and New Password.
											</h3>
										</div>
										<div className={styles.blockContentContainer}>
											<form className={clsx(styles.myForm)} onSubmit={formik1.handleSubmit}>
												<fieldset className={styles.fieldset}>
													<div className={clsx(styles.emailField, styles.myFormFields)}>

														<input

															name="otp"
															placeholder="Enter OTP"
															className={clsx(styles.myInputField, styles.emailInputField)}
															required
															{...formik1.getFieldProps('otp')}
														/>
														{formik1.touched.otp && formik1.errors.otp && (
															<div className={styles.errorMsg}>{formik1.errors.otp}</div>
														)}
													</div>
													<div className={clsx(styles.pwdField, styles.myFormFields)}>
														<input
															name="password"
															placeholder="Enter password"
															className={clsx(styles.myInputField, styles.pwdInputField)}
															required
															{...formik1.getFieldProps('password')}
														/>
														{formik1.touched.password && formik1.errors.password && (
															<div className={styles.errorMsg}>{formik1.errors.password}</div>
														)}
													</div>
													<div className={clsx(styles.pwdField, styles.myFormFields)}>
														<input
															name="confirmPassword"
															placeholder="Enter confirmPassword"
															className={clsx(styles.myInputField, styles.pwdInputField)}
															required
															{...formik1.getFieldProps('confirmPassword')}
														/>
														{formik1.touched.confirmPassword && formik1.errors.confirmPassword && (
															<div className={styles.errorMsg}>
																{formik1.errors.confirmPassword}
															</div>
														)}
													</div>
													{/* <div className={styles.captchaContainer}>
			<div className={styles.captchaContainerContent}>
				<img
					src="img/captcha/captcha.png"
					className={styles.captchaImg}
					alt="JanaviIndia"
				/>
				<button className={styles.reloadBtn}>Reload Captcha</button>
			</div>
		</div> */}
													<div className={styles.actionButtons}>
														<div className={styles.primary}>
															<button
																className={clsx(styles.primaryBtn, 'signIn')}
																name="sendBtn"
																type="submit"
															>
																Reset My Password

															</button>
														</div>
													</div>
												</fieldset>
											</form>
										</div>
									</>
								)}


							</div>
						</div>
					</div>
				</div>
			</section>
			<SocialSection />
			{/* <NewsLetterSection /> */}
		</>
	);
}
