import React from 'react';
import clsx from 'clsx';
import styles from '../../app-header/AppHeader.module.scss';


function CartRemoveConfirm(props) {
    const {onRemoveItemAgreed} = props;
	return (
		<div
			className={clsx(styles.confirmationDialog, 'modal fade')}
			id="staticBackdrop"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			tabIndex="-1"
			aria-labelledby="staticBackdropLabel"
			aria-hidden="true"
		>
			<div className={clsx(styles.modalDialog, 'modal-dialog')}>
				<div className={clsx(styles.modalContent, 'modal-content')}>
					<div className={clsx(styles.modalHeader, 'modal-header')}>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className={clsx(styles.modalBody, 'modal-body')}>
						<p className={(styles.modalBodyContent, 'm-0')}>
							Are you sure you would like to remove this item from the shopping cart?
						</p>
					</div>
					<div className={clsx(styles.modalFooter, 'modal-footer')}>
						<button type="button" className={clsx(styles.btn, 'btn')} data-bs-dismiss="modal">
							Close
						</button>
						<button type="button" className={clsx(styles.btn, 'btn')} data-bs-dismiss="modal" onClick={() => onRemoveItemAgreed()}>
							Ok
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CartRemoveConfirm;
