import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'utils/common';
import { Link } from 'react-router-dom';
import styles from '../../app-header/AppHeader.module.scss';

function CartPopUp(props) {
	const { cartItems = {}, setItemForAction } = props;
	const { cartValue, netPayable } = cartItems;
	const dispatch = useDispatch();

	return (
		<div className={styles.cartPopup}>
			<div className={styles.cartPopupContainer}>
				<div className={styles.cartPopupContentWrap}>
					<div className={styles.cartHeaderContainer}>
						<h5 className={styles.cartHeader}>Cart</h5>
					</div>
				</div>
				{isEmpty(cartItems?.cartItems) ? (
					<div className={clsx(styles.emptyCart, 'd-block')}>You have no items in your shopping cart.</div>
				) : (
					<>
						<div className={styles.filledCart}>
							<div className={styles.cartItemsWrapper}>
								<ol className={styles.cartItemsListBox}>
									{!isEmpty(cartItems?.cartItems) &&
										cartItems.cartItems.map(cartItem => {
											const { finalPrice, productDto = {}, quantity } = cartItem;
											const media =
												!isEmpty(productDto?.listMedia) && productDto?.listMedia[0]?.mediaURL;
											return (
												<li className={styles.listItem}>
													<div className={styles.productCard}>
														<div className={styles.imgBox}>
															<img src={media} alt="janaviIndia" className={styles.img} />
														</div>
														<div className={styles.productDetails}>
															<h4 className={styles.productDetailsTitle}>
																{productDto?.productName}
															</h4>
															<p className={styles.productDetailsSubTitle}>
																Qty: {quantity}
															</p>
															<p className={styles.productDetailsSubTitle}>Color: Red</p>
															<p className={styles.productDetailsSubTitle}>
																Material: 100% Cashmere
															</p>
															<p className={styles.productDetailsSubTitle}>
																Size: Blanket (50x80 inches)
															</p>
															<p className={styles.price}>&#36; {finalPrice} MXN</p>
														</div>
														<div
															className={styles.deleteIcon}
															onClick={() => setItemForAction(cartItem)}
														>
															<i
																className="bi bi-x-circle text-dark"
																data-bs-toggle="modal"
																data-bs-target="#staticBackdrop"
															/>
														</div>
													</div>
												</li>
											);
										})}
								</ol>
							</div>

							{/* {!isEmpty(cartItems) && (
						<> */}
							<div className={styles.subTotal}>
								<p className={styles.orderSummaryTitle}>Order Summary</p>
								<div className={styles.mainSubTotalWrap}>
									<div className={clsx(styles.subTotalPrice, 'd-flex flex-row')}>
										<p className="m-0">Subtotal: </p>
										<p className="m-0">&#36;{netPayable} MXN</p>
									</div>
									<div className={clsx(styles.subTotalPrice, 'd-flex flex-row')}>
										<p className="m-0">Shipping: </p>
										<p className="m-0">&#36;{cartItems?.shippingAmount} MXN</p>
									</div>
									<div className={clsx(styles.subTotalPrice, 'd-flex flex-row')}>
										<p className="m-0">Discount: </p>
										<p className="m-0">&#36;{cartItems?.discountAmount} MXN ({cartItems?.discountPercentage+'%'})</p>
									</div>
									<div className={clsx(styles.totalPrice, 'd-flex flex-row')}>
										<p className="m-0">Total: </p>
										<p className="m-0">&#36;{cartValue} MXN</p>
									</div>
								</div>
							</div>
							<div className={styles.actionBtn}>
								<Link to="/checkout-cart" className="text-decoration-none">
									<button type="button" name="checkoutBtn" className={styles.checkoutBtn}>
										Checkout
									</button>
								</Link>
								<Link to="/view-cart" className="text-decoration-none">
									<button type="button" name="viewCartBtn" className={styles.viewCartBtn}>
										View Cart
									</button>
								</Link>
							</div>
							{/* </> */}
							{/* // )} */}
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default CartPopUp;
