import { combineReducers } from 'redux';
import counterReducer from '../views/counter/counterSlice';
import i18n from './i18nSlice';
import common from './common/commonSlice';
import productSlice from './product/productSlice';
import shopping from './shopping/shoppingSlice';

const reducers = combineReducers({
	counter: counterReducer,
	i18n,
	common,
	product: productSlice,
	shopping
});

export default reducers;
