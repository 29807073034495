import React from 'react';
import clsx from 'clsx';
import styles from './SocialSection.module.scss';

export default function SocialSection() {
	return (
		<>
			<section className={styles.socialSection}>
				<div className={styles.myContainer}>
					<div className={styles.instagramFeed}>
						<h3 className={clsx(styles.sectionHead, 'sectionHead')}>Follow Us on Instagram</h3>
						<p className={styles.subHead}>
							<a
								href="https://www.instagram.com/janaviindia/"
								target="_blank"
								className={styles.handler}
								rel="no noreferrer"
							>
								@janavi<span>india</span>
							</a>
						</p>
						{/* <iframe
							src="https://cdn.lightwidget.com/widgets/1f45898d9c89555593347ec36aa25d31.html"
							scrolling="no"
							allowTransparency="true"
							className={clsx(styles.lightWidget, 'lightwidget-widget')}
							title="janaviIndia"
						/> */}
						<iframe title="instaFeed" 
								className={clsx(styles.instaFeed, 'w-100')} 
								src="https://embedsocial.com/facebook_album/pro_hashtag/a33df6592d1ab82928a306b916082009fe7abb16" 
								width="900px" 
								height="900px" 
								frameborder="0" 
								marginheight="0" 
								marginwidth="0" />


					</div>
				</div>
			</section>
		</>
	);
}
