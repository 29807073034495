import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

// For GET requests
axios.interceptors.request.use(
  (req) => {
    // Add configurations here
   return req;
  },
  (error) => {
    return Promise.reject(error);

  }
);

// For POST requests
axios.interceptors.response.use(
  (res) => {
 
     if (res.status === 401) {
      // alert("hi");
      // console.log("bye");
      // localStorage.removeItem('token');
      // localStorage.removeItem('loginId');
      // localStorage.removeItem('userProfile');
      // localStorage.clear();
      // history.push('/');
      // window.location.reload();
    }
    return res;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);

  }
);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
