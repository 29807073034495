import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'utils/common';
import * as requestFromServer from './authCrud';
import { setAlertMsg } from './commonSlice';

export const authenticateLoginApi = createAsyncThunk('ncr2/authenticateLoginApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.login(params);
		if (response.data) {
			const { data } = response;
			if (!isEmpty(data) && data.loginStatus) {
				console.log('user exists');
				if (!isEmpty(data.loginToken)) {
					localStorage.setItem('token', data.accessToken);
					localStorage.setItem('loginId', data.loginToken);
					localStorage.setItem(
						'userProfile',
						JSON.stringify({ userFirstName: data.userFirstName, userLastName: data.userLastName })
					);
					localStorage.setItem(
						'userSession',
						JSON.stringify(data)
					);
					return data;
				}
				return thunkApi.rejectWithValue({
					message: 'There is some problem while Loggin in. please try again'
				});
			}
			return thunkApi.rejectWithValue({
				message: 'There is some problem while Loggin in. please try again'
			});
		}
		return thunkApi.rejectWithValue('There is error in login');
	} catch (error) {
		return thunkApi.rejectWithValue(error?.response?.data);
	}
});
export const registerUserForNewsLetterApi = createAsyncThunk(
	'ncr2/registerUserForNewsLetterApi',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.registerUserForNewsLetter(params);
			if (response.data) {
				const { data } = response;
				thunkApi.dispatch(
					setAlertMsg({
						message: data,
						type: 'success'
					})
				);
				return data;
			}
			return thunkApi.rejectWithValue('There is error in login');
		} catch (error) {
			error.clientMessage = 'Error login ';
			return thunkApi.rejectWithValue({ error });
		}
	}
);

export const forgotYourPasswordAPi = createAsyncThunk('ncr2/forgotYourPasswordAPi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.resetYourPassword(params);
		if (response.data) {
			const { data } = response;
			// thunkApi.dispatch(
			// 	setAlertMsg({
			// 		message: data,
			// 		type: 'success'
			// 	})
			// );
			return data;
		}
		return thunkApi.rejectWithValue('There is error in login');
	} catch (error) {
		error.clientMessage = 'Error login ';
		return thunkApi.rejectWithValue({ error });
	}
});

export const sendOtpApi = createAsyncThunk('ncr2/sendOtpApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.sendOtp(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: "OTP SENT",
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is error in login');
	} catch (error) {
		error.clientMessage = 'Error login ';
		return thunkApi.rejectWithValue({ error });
	}
});

export const verifyOtpApi = createAsyncThunk('ncr2/verifyOtpApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.verifyOtp(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: "Password changed, Login and shop with us.",
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is error in login');
	} catch (error) {
		error.clientMessage = 'Error login ';
		return thunkApi.rejectWithValue({ error });
	}
});




export const passwordResetApi = createAsyncThunk('ncr2/passwordResetApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.passwordReset(params);
		if (response.data) {
			const { data } = response;
			thunkApi.dispatch(
				setAlertMsg({
					message: "Password changed, Login and shop with us.",
					type: 'success'
				})
			);
			return data;
		}
		return thunkApi.rejectWithValue('There is error in login');
	} catch (error) {
		error.clientMessage = 'Error login ';
		return thunkApi.rejectWithValue({ error });
	}
});

export const registerUserApi = createAsyncThunk('ncr2/registerUserApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.register(params);
		if (response.data) {
			const { data } = response;
			if (!isEmpty(data)) {
				console.log('user exists');
				return data;
			}
			return thunkApi.rejectWithValue({
				message: 'There is some problem while Loggin in. please try again'
			});
		}
		return thunkApi.rejectWithValue('There is error in signup');
	} catch (error) {
		if (error.response)
			return thunkApi.rejectWithValue(error.response.data);
	}
});
export const viewProfileApi = createAsyncThunk('ncr2/viewProfileApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.viewProfile(params);
		if (response.data) {
			const { data } = response;
			if (!isEmpty(data)) {
				return data;
			}
			return thunkApi.rejectWithValue({
				message: 'There is some problem while view profile. please try again'
			});
		}
		return thunkApi.rejectWithValue('There is error in view profile');
	} catch (error) {
		if (error.response)
			return thunkApi.rejectWithValue(error.response.data);
	}
});

export const updateUserProfileApi = createAsyncThunk('ncr2/updateUserProfileApi', async (params, thunkApi) => {
	try {
		const response = await requestFromServer.updateUserProfile(params);
		if (response.data) {
			const { data } = response;
			if (!isEmpty(data)) {

				thunkApi.dispatch(
					setAlertMsg({
						message: 'Profile edited successfully',
						type: 'success'
					})
				);
				return data;
			}


			return thunkApi.rejectWithValue({
				message: 'There is some problem while view profile. please try again'
			});
		}
		return thunkApi.rejectWithValue('There is error in view profile');
	} catch (error) {
		if (error.response)
			return thunkApi.rejectWithValue(error.response.data);
	}
});