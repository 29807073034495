import { createSlice } from '@reduxjs/toolkit';
import {
	addToCartApi,
	getCartItemsApi,
	removeFromCartApi,
	getCustomerWishlistProductsApi,
	addCouponCodeToCartApi,
	removeCouponCodeFromCartApi,
	addMessageToCartApi,
	placeOrderApi,
	clearCartApi
} from './shoppingApi';

const initialState = {
	cartItems: {},
	cartId: '',
	wishlistProducts: []
};

const shoppingSlice = createSlice({
	name: 'shopping',
	initialState,
	reducers: {
		// findProducts
	},
	extraReducers: {
		[addMessageToCartApi.fulfilled]: (state, action) => {
			state.cartItems = action.payload.Cart;
			state.cartId = action.payload.Cart.cartId;
			localStorage.setItem('cartId', action.payload.Cart.cartId);
		},
		[placeOrderApi.fulfilled]: (state, action) => {
			state.cartItems = action.payload;
			state.cartId = action.payload.cartId;
			localStorage.setItem('cartId', action.payload.cartId);
		},

		[addCouponCodeToCartApi.fulfilled]: (state, action) => {
			state.cartItems = action.payload;
			state.cartId = action.payload.cartId;
			localStorage.setItem('cartId', action.payload.cartId);
		},
		[removeCouponCodeFromCartApi.fulfilled]: (state, action) => {
			state.cartItems = action.payload;
			state.cartId = action.payload.cartId;
			localStorage.setItem('cartId', action.payload.cartId);
		},
		[addToCartApi.fulfilled]: (state, action) => {
			state.cartItems = action.payload;
			state.cartId = action.payload.cartId;
			localStorage.setItem('cartId', action.payload.cartId);
		},
		[getCartItemsApi.fulfilled]: (state, action) => {
			state.cartItems = action.payload?.Cart;
			state.cartId = action.payload.cartId;
			localStorage.setItem('cartId', action.payload?.Cart?.cartId);
		},
		[removeFromCartApi.fulfilled]: (state, action) => {
			state.cartItems = action.payload;
			state.cartId = action.payload.cartId;
			localStorage.setItem('cartId', action.payload.cartId);
		},
		[clearCartApi.fulfilled]: (state, action) => {
			state.cartItems = action.payload;
			state.cartId = action.payload.cartId;
			localStorage.setItem('cartId', action.payload.cartId);
		},
		[getCustomerWishlistProductsApi.fulfilled]: (state, action) => {
			state.wishlistProducts = action.payload;
		}
	}
});

export default shoppingSlice.reducer;
