import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { setAuthModal } from 'store/common/commonSlice';
import AUTH_MODAL from 'utils/constants/authModalConst';

function PrivateRoute(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const [isLoggedIn, setLoggedIn] = useState(false);

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			setLoggedIn(false);
			dispatch(setAuthModal(AUTH_MODAL.LOGIN));
			history.goBack();
		} else {
			setLoggedIn(true);
		}
	},[]);

	return isLoggedIn ? <Route {...props} /> : null;
}

export default PrivateRoute;
