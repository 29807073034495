import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setAuthModal } from 'store/common/commonSlice';
import { registerUserApi, registerUserForNewsLetterApi } from 'store/common/authApi';
import AUTH_MODAL from 'utils/constants/authModalConst';
import { desiredRole } from 'utils/constants/constants';
import { unwrapResult } from '@reduxjs/toolkit';
import styles from './LoginModal.module.scss';

const fnameErrorMsg = '"First Name" is a required value.';
const lnameErrorMsg = '"Last Name" is a required value.';

const initialValues = {
	// phoneNumber: '',
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	confirmPassword: '',
	isTermsAgreed: false
};

const signupSchema = Yup.object().shape({

	// phoneNumber: Yup.number().required(),
	firstName: Yup.string().required(fnameErrorMsg),
	lastName: Yup.string().required(lnameErrorMsg),
	email: Yup.string().required('Email is requred value').email(),
	password: Yup.string()
		.min(3, 'Minimum 8 characters allowed')
		.max(50, 'Maximum 50 characters allowed')
		.required('This is a required field'),
	confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
	isTermsAgreed: Yup.boolean().oneOf([true], 'Field must be checked')
});

export default function SignUpModal() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [isSubsNewsLetter, setSubsNewsLetter] = useState(false);

	const onCloseModal = () => {
		dispatch(setAuthModal(null));
	};

	const formik = useFormik({
		initialValues,
		validationSchema: signupSchema,
		onSubmit: (values, formikProps) => {
			setLoading(true);
			formikProps.setSubmitting(true);
			dispatch(
				registerUserApi({
					username: values.email,
					email: values.email,
					password: values.password,
					username: values.email,
					fullname: `${values.firstName}, ${values.lastName}`,
					desiredRole: desiredRole.ROLE_USER,
					merchant: false,
					loginToken: ''
				})
			)
				.then(unwrapResult)
				.then(data => {
					// const { accessToken } = data;
					// const user = {
					// 	...data
					// };
					// delete user.accessToken;

					if (data) {
						if (data.loginStatus) {
							localStorage.setItem('token', data.accessToken);
							localStorage.setItem('loginToken', data.loginToken);
							localStorage.setItem(
								'userProfile',
								JSON.stringify({ userFirstName: data.userFirstName, userLastName: data.userLastName })
							);
							dispatch(setAuthModal(null));
							if (isSubsNewsLetter) {
								dispatch(registerUserForNewsLetterApi({ email: values.email }));
							}
						}
					} else {
						formikProps.setStatus(data?.message || 'Something went wrong while signup. Please try again!');
					}

					formikProps.setSubmitting(false);
				})
				.catch(data => {
					setLoading(false);
					formikProps.setSubmitting(false);
					formikProps.setStatus(data?.message || 'Something went wrong while signup. Please try again!');
				});
		}
	});

	const onBackClick = () => {
		dispatch(setAuthModal(AUTH_MODAL.LOGIN));
	}

	return (
		<>
			<div className={styles.loginSignUpModal}>
				<div className="formOverlay" />
				<div className={styles.loginSignUpForm}>
					<div className={styles.closePopup} onClick={onCloseModal} />
					<div className={styles.popupLogo} />

					{/* Create An Account Modal */}
					<div className={clsx(styles.formPage1, styles.formPage)}>
						<div className={clsx(styles.closePopup, styles.showOnMob)} onClick={onCloseModal} />
						<div className="customerLoginBlock">
						
							<div className={styles.blockTitleContainer}>
								<h3 className={styles.blockTitle}>Create An Account</h3>
							</div>
							<div className={styles.blockContentContainer}>
								{/* <div className={styles.socialLogin}>
									<ul className={styles.blockContent}>
										<li
											className={clsx(
												styles.blockContentList,
												'actions-toolbar social-btn facebook-login'
											)}
										>
											<a className={clsx(styles.socialLoginLink, 'btn btn-block')}>
												<i className={clsx(styles.fontAwesome, 'bi bi-facebook')} />
											</a>
										</li>
										<li
											className={clsx(
												styles.blockContentList,
												'actions-toolbar social-btn google-login'
											)}
										>
											<a className={clsx(styles.socialLoginLink, 'btn btn-block')}>
												<i className={clsx(styles.fontAwesome, 'bi bi-google')} />
											</a>
										</li>
									</ul>
								</div>

								<div className={styles.formDivider}>
									<h5 className={styles.dividerContent}>OR</h5>
								</div> */}

								<form className={clsx(styles.myForm)} onSubmit={formik.handleSubmit}>
									<fieldset className={styles.fieldset}>
										<div className={styles.myCustomField}>
											<div className={clsx(styles.fnameField, styles.myFormFields)}>
												<input
													name="firstName"
													placeholder="First Name"
													className={clsx(styles.myInputField, styles.fnameInputField)}
													{...formik.getFieldProps('firstName')}
												/>
												{formik.touched.firstName && formik.errors.firstName && (
													<div className={styles.errorMsg}>{formik.errors.firstName}</div>
												)}
											</div>
											<div className={clsx(styles.lnameField, styles.myFormFields)}>
												<input
													name="lastName"
													placeholder="Last Name"
													className={clsx(styles.myInputField, styles.LnameInputField)}
													{...formik.getFieldProps('lastName')}
												/>
												{formik.touched.lastName && formik.errors.lastName && (
													<div className={styles.errorMsg}>{formik.errors.lastName}</div>
												)}
												{/* <div className={styles.errorMsg}>This is a required field</div> */}
											</div>
										</div>




										{/* <div className={clsx(styles.emailField, styles.myFormFields)}>
											<input
												name="phoneNumber"
												placeholder="Phone Number"
												className={clsx(styles.myInputField, styles.emailInputField)}
												{...formik.getFieldProps('phoneNumber')}
											/>
											{formik.touched.phoneNumber && formik.errors.phoneNumber && (
												<div className={styles.errorMsg}>{formik.errors.phoneNumber}</div>
											)}
										</div> */}
										<div className={clsx(styles.emailField, styles.myFormFields)}>
											<input
												name="email"
												placeholder="Email address"
												className={clsx(styles.myInputField, styles.emailInputField)}
												{...formik.getFieldProps('email')}
											/>
											{formik.touched.email && formik.errors.email && (
												<div className={styles.errorMsg}>{formik.errors.email}</div>
											)}
										</div>
										<div className={styles.myCustomField}>
											<div className={clsx(styles.pwdField, styles.myFormFields)}>
												<input
													name="password"
													placeholder="Enter Password"
													type="password"
													className={clsx(styles.myInputField, styles.pwdInputField)}
													{...formik.getFieldProps('password')}
												/>
												{formik.touched.password && formik.errors.password && (
													<div className={styles.errorMsg}>{formik.errors.password}</div>
												)}
											</div>
											<div className={clsx(styles.pwdField, styles.myFormFields)}>
												<input
													name="confirmPassword"
													type="password"
													placeholder="Confirm Password"
													className={clsx(styles.myInputField, styles.pwdInputField)}
													{...formik.getFieldProps('confirmPassword')}
												/>
												{formik.touched.confirmPassword && formik.errors.confirmPassword && (
													<div className={styles.errorMsg}>
														{formik.errors.confirmPassword}
													</div>
												)}{' '}
											</div>
										</div>
										<div className={styles.subscribeNewsletter}>
											<div className={styles.customCheckBoxContainer}>
												<input
													type="checkbox"
													name="is_subscribed"
													title="Sign Up for Newsletter"
													checked={isSubsNewsLetter}
													onChange={({ target: { checked } }) => setSubsNewsLetter(checked)}
													id="is_subscribed"
													className={clsx(styles.checkbox, 'checkbox')}
												/>
												<div className={styles.roundCheck} />
												<i className={clsx(styles.checkTick, 'bi bi-check2')} />
											</div>
											<label
												htmlFor="is_subscribed"
												className={clsx(styles.checkboxLabel, styles.subscribe)}
											>
												Subscribe to our newsletter
											</label>
										</div>
										<div className={styles.termsConditions}>
											<div className={styles.customCheckBoxContainer}>
												<input
													type="checkbox"
													name="isTermsAgreed"
													checked={formik.values.isTermsAgreed}
													id="is_terms"
													className={clsx(styles.checkbox, 'checkbox')}
													{...formik.getFieldProps('isTermsAgreed')}
												/>
												<div className={styles.roundCheck} />
												<i className={clsx(styles.checkTick, 'bi bi-check2')} />
											</div>
											<label htmlFor="is_terms" className={styles.checkboxLabel}>
												I agree to the website
												<a
													href="./terms-conditions"
													target="_blank"
													className={styles.termsLink}
												>
													Terms &amp; Conditions{' '}
												</a>
												I agree to receiving e-mails for service related informations
											</label>
											{formik.touched.isTermsAgreed && formik.errors.isTermsAgreed && (
												<div className={styles.errorMsg}>{formik.errors.isTermsAgreed}</div>
											)}{' '}
										</div>
										{formik.status && (
								<div className={styles.responseMsg}>
									<div className={styles.errorMsg}>{formik.status}</div>
								</div>
							)}
										<div className={styles.actionButtons}>
											<div className={styles.primary}>
												{!formik.isSubmitting ? (
													<button
														className={clsx(styles.primaryBtn, styles.signIn)}
														name="sendBtn"
														type="submit"
														disabled={formik.isSubmitting}
													>
														Sign Up
													</button>
												) : (
													<div className="spinner-border" role="status">
														<span className="visually-hidden">Loading...</span>
													</div>
												)}
											</div>
										</div>
									</fieldset>
								</form>

								<div className={styles.secondary}>
									<button className={styles.secondaryBtn} name="sendBtn" onClick={() => { onBackClick() }}>
										Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
