import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import useScrollPosition from 'hooks/useScrollPosition';
import { NavLink, Link } from "react-router-dom";

import { unwrapResult } from '@reduxjs/toolkit';
import styles from './AppHeader.module.scss';
import { searchProductApi } from 'store/product/productsApi';

import models from 'utils/constants/models';
import AUTH_MODAL from 'utils/constants/authModalConst';
import { setAuthModal, setPageSectionData } from 'store/common/commonSlice';
import { isEmpty } from 'utils/common';
import { useHistory } from 'react-router-dom';
import { getCustomerWishlistProductsApi, removeFromCartApi } from 'store/shopping/shoppingApi';
import CartRemoveConfirm from 'layout/components/cart-popup/CartConfirm';
import CartPopUp from '../components/cart-popup/CartPopup';
const womenMenu1 = [
    {
        path: "/",
        name: "Classics"
    },
    {
        path: "/",
        name: "Love"
    },
    {
        path: "/",
        name: "Resort"
    },
    {
        path: "/",
        name: "Geometrics"
    },
    {
        path: "/",
        name: "Florals"
    },
    {
        path: "/",
        name: "Celestial"
    }

];
const womenMenu2 = [
    {
        path: "/",
        name: "Ritz"
    },
    {
        path: "/",
        name: "Laces"
    },
    {
        path: "/",
        name: "Spectaculars"
    },
    {
        path: "/",
        name: "Janavi India X Kiera Chaplin"
    },
    {
        path: "/",
        name: "Janavi India X Lotus Arts De Vivre"
    }
];
const womenFeatured = [
    {
        path: "",
        imgPath: "https://janaviindia.com/media/catalog/category/kieraNav_1.jpg",
        altName: "Janavi India",
        name: "Janavi India X Kiera Chaplin"
    },
    {
        path: "",
        imgPath: "https://janaviindia.com/media/catalog/category/womenRitzSmall.jpg",
        altName: "Janavi India",
        name: "Ritz"
    },
];

const menMenu1 = [
    {
        path: "/",
        name: "Checks"
    },
    {
        path: "/",
        name: "Classics"
    }

];
const menMenu2 = [
    {
        path: "/",
        name: "Stripes"
    },
    {
        path: "/",
        name: "Resort"
    }
];
const menFeatured = [
    {
        path: "",
        imgPath: "https://janaviindia.com/media/catalog/category/checksThumb.jpg",
        altName: "Janavi India",
        name: "Checks"
    },
    {
        path: "",
        imgPath: "https://janaviindia.com/media/catalog/category/classicsThumb.jpg",
        altName: "Janavi India",
        name: "Classics"
    },
];

const homeMenu = [
    {
        path: "/",
        name: "Blankets"
    },
    {
        path: "/",
        name: "Cushions"
    }
];

const babyMenu = [
    {
        path: "/",
        name: "Blankets"
    },
    {
        path: "/",
        name: "Toys"
    },
    {
        path: "/",
        name: "Blanket Sets"
    }
];


function AppMobHeader(props) {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setSearchValue(event.target.value);
            setSearchActivated(false);
            history.push('/search-list?type=search&text=' + event.target.value);
            setSearchActivated(false);
        }
    }
    useEffect(() => {
        getWishlistProducts();
    }, []);
    // Logout
    const onLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('loginId');
        localStorage.removeItem('userProfile');
        localStorage.clear();
        history.push('/');
        window.location.reload();
    };
    // const user = JSON.parse('userName');
    const user = localStorage.getItem('userProfile');
    const userName = JSON.parse(user);
    const getWishlistProducts = () => {
        const params = {
            otherReferenceId: '',
            pageNo: 0,
            pageSize: 10,
            sortOption: 'Desc',
            sortOrder: 'ASC'
        };
        dispatch(getCustomerWishlistProductsApi(params));
    };
    const onRemoveItemAgreed = () => {
        const param = {
            ...models.cartItem,
            cartId: isEmpty(localStorage.getItem('cartId')) ? '' : localStorage.getItem('cartId'),
            // productId: product.productId,
            quantity: itemForAction?.quantity,
            productId: itemForAction?.productDto?.productId
        };
        dispatch(removeFromCartApi(param));
    };
    const [itemForAction, setItemForAction] = useState({});
    const { cartItems = {} } = useSelector(({ shopping }) => shopping);
    const { wishlistProducts = [] } = useSelector(state => state.shopping);
    const wishProdLength = Array.isArray(wishlistProducts) && wishlistProducts.length;

    const [isScrolled, setScrolled] = useState(false);
    const [menuClicked, setMenuClicked] = useState(false);
    const [menuItemClicked, setMenuItemClicked] = useState(false);

    const [caretClicked, toggleCaret] = useState(false);

    const dispatch = useDispatch();
    const { pageMetaData = {}, pageSectionData = {} } = useSelector(({ common }) => common);
    const listSection =
        (Array.isArray(pageMetaData?.listSection) &&
            pageMetaData?.listSection.find(sec => sec.verticalDisplayOrder === 1)) ||
        {};
    const { categories = [] } = useSelector(({ common }) => common);
    const sectionData = (pageSectionData && pageSectionData[listSection?.sectionKey]) || {};

    useScrollPosition(
        ({ prevPos, currPos }) => {
            console.log("Curr Y position is: " + currPos.y);
            const isShow = Math.abs(currPos.y) > 50
            console.log("isShow: " + isShow);
            console.log("isScrolled: " + isScrolled)
            if (isShow !== isScrolled) setScrolled(isShow)
        },
        [isScrolled],
        null,
        false,
        300
    )
    const [optLoading, setOptLoading] = useState(false);
    const [searchActivated, setSearchActivated] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [searchIcon, setSearchIcon] = useState(false);
    const onProductSearch = value => {

        if (value == "") {
            setSearchList([]);
            setSearchActivated(false);
        } else {
            const params = {
                filter: 'TextSearch',
                pageSize: 20,
                pageNo: 0,
                sendAllProducts: true,
                otherReferenceId: '',
                categoryId: '',
                sortOption: 'productName',
                sortProduct: 'ASC',
                storeId: window?.env?.STORE_ID,
                searchString: value
            };
            dispatch(searchProductApi(params))
                .then(unwrapResult)
                .then(data => {
                    const { products = [] } = data;
                    setSearchList(products);
                    setSearchActivated(true);
                })

                .catch(err => {
                    console.error('err is', err);
                    setOptLoading(false);
                });
        }


    };
    const removeDups = (arr) => {

        if (arr?.length) {
            return arr.filter((thing, index) => {
                const _thing = JSON.stringify(thing);
                return index == arr.findIndex(obj => {
                    return JSON.stringify(obj) === _thing;
                });
            });
        } else {
            return [];
        }

    }
    // const [searchValue, setSearchValue] = useState(undefined);
    const goToProduct = (item) => {
        setSearchActivated(false);
        let clearTxt = "";
        setSearchValue(clearTxt);
        history.push('/product-detail/' + item.productId);

    }

    const [searchValue, setSearchValue] = useState();
    const searchProductDebounce = useCallback(_.debounce(onProductSearch, 300), []);

    function handleSearch(event) {
        if (event.target.value !== '' || event.target.value !== null) {
            if (event.target.value.length >= 3) {
                searchProductDebounce(event.target.value);
            } else {
                setSearchList([]);
                setSearchActivated(false);
            }
        }
        setSearchValue(event.target.value);
    }


    const history = useHistory();

    useEffect(() => {
        history.listen((location, action) => {
            setMenuClicked(false);
            console.log("on route change");
            
            setSearchActivated(false);
        });
    }, []);

    return (

        <header className={clsx(styles.mainMobHeader, { [styles.mainHeaderScrolled]: isScrolled })}>
            <div className={clsx(styles.header_top, 'd-none')}>
                <div className="container p-0">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className={clsx(styles.header_top_msg, "text-center")} >
                                <p>Delivery delays may occur given the current government restrictions. We apologise for any inconvenience caused.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.header_bottom}>
                <div className={clsx(styles.myContainer, "container-fluid")}>
                    <div className={clsx(styles.headerWrapper, { [styles.headerWrapperScrolled]: isScrolled })}>
                        <div className={styles.navSection}>
                            <div className={styles.navSectionItems}>
                                <div className={styles.mobileSunCrossWrap}>
                                    <div className={styles.sunLogo}>
                                        <i className={clsx(styles.hamburger, "bi bi-list")} onClick={() => setMenuClicked(true)}></i>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={clsx(styles.logoBg, { [styles.logoBgScrolled]: isScrolled })}>
                            <Link to={'/'} className={styles.linkItem}>
                                <img className={styles.logoMain}
                                    src="https://janaviindia.com/media/logo/stores/1/logo.png" alt="JanaviIndia"
                                    width="170" />
                            </Link>
                        </div>

                        <div className={styles.headerActions}>

                            <div className={clsx(styles.headerActionIcons, 'w-50')}>
                                <ul className={styles.iconList}>


                                    <li className={clsx(styles.iconListItem, styles.greetingMsgBox)}>
                                        {userName && (
                                            <span className={styles.greetingMsg}>
                                                {' '}
                                                Hi {userName?.userFirstName}!
                                            </span>
                                        )}
                                        <Link to="/view-cart" className={clsx(styles.actionLink, styles.cartIcon)}>
                                            <img className={styles.actionImg} src="../img/headerActions/shopping-bag.svg" title="My Cart" alt="My Cart" />
                                            <span className={styles.cartCounter}>
                                                <span className={styles.cartCounterNumber}>
                                                    {!isEmpty(cartItems) ? cartItems.countOfProducts : 0}
                                                </span>
                                            </span>
                                        </Link>
                                    </li>


                                </ul>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
            <div className={clsx(styles.headerDrawer, menuClicked ? 'd-block' : 'd-none')}>
                <div className={styles.drawerContent}>
                    <div className={clsx(styles.myContainer, "container-fluid")}>
                        <div className={styles.closeBtn}>
                            <i className="bi bi-x-circle" onClick={() => setMenuClicked(false)}></i>
                        </div>
                        <div className={styles.imgBox}>
                            <Link to={'/'} className={styles.linkItem}>
                                <img className={styles.sunLogo} src="../img/logo/sunLogo.png" />
                            </Link>
                        </div>
                        <div className={styles.searchBlock}>
                            {/* <input id="search" type="text" name="Search" placeholder="Search..." className={styles.inputArea} maxLength={128} aria-autocomplete="both" autocomplete="off" /> */}

                            <input

                                onKeyPress={event => handleKeyPress(event)}

                                onChange={event => handleSearch(event)}
                                value={searchValue}
                                id="search"
                                type="text"
                                name="Search"
                                placeholder="Search..."
                                className={styles.inputArea}
                                maxLength={128}
                                aria-autocomplete="both"
                                autoComplete="off"
                                autoFocus
                            />


                            <img className={styles.searchIcon}
                                src="../img/headerActions/search.svg"
                                title="Search"
                                onClick={() => setSearchActivated(!searchActivated)}
                                alt="Search"
                            />



                            {searchActivated && (
                                <div id="search_autocomplete" className={styles.searchAutoComplete} onClick={() => setSearchActivated(false)}>

                                    {searchList.length ? <ul className={styles.searchList}>
                                        {searchList.map(item => (
                                            <li className={styles.searchListItem}>

                                                <a onClick={() => goToProduct(item)}>

                                                    <Link className={styles.searchListItemLink}>
                                                        {item?.productName}
                                                    </Link>
                                                </a>
                                            </li>
                                        ))}
                                    </ul> :

                                        <ul className={styles.searchList}>
                                            <li className={styles.searchListItem}>
                                                <p>No results</p>
                                            </li>
                                        </ul>
                                    }





                                </div>
                            )}




                        </div>
                        <div className={styles.menuBar}>

                            <nav className={styles.menuItems}>
                                <ul className={clsx(styles.menuList, 'm-0')}>


                                    {/* Main menu */}
                                    <li className={styles.menuListItem}>

                                        <Link to={`/product-list?type=category&referenceId=1`} className={clsx(styles.menuListItemLink)}
                                        >
                                            What's New
                                        </Link>
                                    </li>

                                    {removeDups(sectionData?.Categories).map((category, index) => {
                                        const {
                                            categoryName = '',
                                            categoryId = '',
                                            subCategory = []
                                        } = category || {};
                                        return (
                                            <li className={styles.menuListItem}>
                                                {
                                                    menuItemClicked === categoryId ?
                                                        // to={`/product-list?type=category&referenceId=${categoryId}`} 
                                                        <span className={'d-flex flex-direction-row justify-content-between align-items-center'}>
                                                            <NavLink
                                                                to={`/product-list?type=category&referenceId=${categoryId}`} className={clsx(styles.menuListItemLink)}

                                                            >
                                                                {categoryName}
                                                            </NavLink>
                                                            <i className="bi bi-chevron-up" onClick={() => setMenuItemClicked(menuItemClicked === categoryId ? null : categoryId)} />
                                                        </span>
                                                        :
                                                        <span className={'d-flex flex-direction-row justify-content-between align-items-center'}>
                                                            <NavLink
                                                                to={`/product-list?type=category&referenceId=${categoryId}`} className={clsx(styles.menuListItemLink)}
                                                            >
                                                                {categoryName}

                                                            </NavLink>
                                                            <i className="bi bi-chevron-down" onClick={() => setMenuItemClicked(menuItemClicked === categoryId ? null : categoryId)} />
                                                        </span>
                                                }
                                                {/* <i className="bi bi-chevron-up"></i> */}
                                                <ul className={clsx(styles.subItemsList, menuItemClicked === categoryId ? 'd-block' : 'd-none')}>
                                                    {/* <li className={styles.subMenuListItem}>
                                                        <NavLink
                                                            to={`/product-list?type=category&referenceId=${categoryId}`} className={clsx(styles.menuListItemLink)}
                                                        >
                                                            ALL
                                                        </NavLink>

                                                    </li> */}
                                                    {subCategory.map(item => (

                                                        <li className={styles.subMenuListItem}>
                                                            <NavLink
                                                                to={`/product-list?type=subCategory&referenceId=${item?.subCategoryId}&parentId=${categoryId}`}
                                                                className={styles.subMenuListItemLink}
                                                            >
                                                                {item?.subCategoryName}
                                                            </NavLink>

                                                        </li>
                                                    ))}

                                                </ul>
                                            </li>

                                        );

                                    })}
                                </ul>



                                {user ? (
                                    <ul className={styles.menuList}>
                                        {/* Login, Wishlist */}

                                        <li className={styles.menuListItem}>
                                            <Link to="/wishlist" className={clsx(styles.menuListItemLink)}>
                                                WISHLIST

                                            </Link>
                                        </li>
                                        <li className={styles.menuListItem}>
                                            <Link to={`my-orders`} className={clsx(styles.menuListItemLink)}>
                                                My Orders

                                            </Link>
                                        </li>
                                        <li className={styles.menuListItem}>
                                            <Link to=""
                                                onClick={onLogout}
                                                className={clsx(styles.menuListItemLink)}
                                            >
                                                Sign Out

                                            </Link>
                                        </li>

                                    </ul>

                                ) : (
                                    <ul className={styles.menuList}>
                                        <li className={styles.menuListItem}>
                                            <Link to="" className={clsx(styles.menuListItemLink)} onClick={() => dispatch(setAuthModal(AUTH_MODAL.LOGIN))}>
                                                Login
                                            </Link>
                                        </li>
                                    </ul>
                                )}





                            </nav>
                        </div>

                    </div>
                </div>
            </div>

        </header>

    )
}
export default AppMobHeader;
