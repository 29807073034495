import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setAuthModal } from 'store/common/commonSlice';
import AUTH_MODAL from 'utils/constants/authModalConst';
import { NavLink } from 'react-router-dom';
import { authenticateLoginApi } from 'store/common/authApi';
import ForgetPassword from 'views/auth/forget-password/ForgetPassword';
import { unwrapResult } from '@reduxjs/toolkit';
import styles from './LoginModal.module.scss';
import { getCartItems } from 'store/shopping/shoppingCrud';
import { getCartItemsApi } from 'store/shopping/shoppingApi';
const initialValues = {
	usernameOrEmail: '',
	password: '',
	storeId: window?.env?.STORE_ID
};

const LoginSchema = Yup.object().shape({
	usernameOrEmail: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('This is a required field'),
	password: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('This is a required field')
});

export default function LoginModal() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const onSignUp = () => {
		dispatch(setAuthModal(AUTH_MODAL.SIGNUP));
	};

	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: (values, formikProps) => {
			setLoading(true);
			formikProps.setSubmitting(true);
			dispatch(
				authenticateLoginApi({
					usernameOrEmail: values.usernameOrEmail,
					storeId: values.storeId,
					password: values.password
				})
			)
				.then(unwrapResult)
				.then(data => {
					dispatch(getCartItemsApi({ cartId: localStorage.getItem('cartId') }));
					formikProps.setSubmitting(false);
					onCloseModal();
					// dispatch(getCartItems({}));
				
				})
				.catch(() => {
					setLoading(false);
					formikProps.setSubmitting(false);
					// formikProps.setStatus('Invalid login or password');
				});
		}
	});

	const onCloseModal = () => {
		dispatch(setAuthModal(null));
	};

	return (
		<>
			<div className={styles.loginSignUpModal}>
				<div className="formOverlay" />
				<div className={styles.loginSignUpForm}>
					<div className={styles.closePopup} onClick={onCloseModal} />
					<div className={styles.popupLogo} />

					{/* Login Modal */}
					<div className={clsx(styles.formPage)}>
						<div className={clsx(styles.closePopup, styles.showOnMob)} onClick={onCloseModal} />
						<div className="customerLoginBlock">
							<div className={styles.blockTitleContainer}>
								<h3 className={styles.blockTitle}>Sign In</h3>
							</div>
							<div className={styles.blockContentContainer}>
								{formik.status && (
									<div className={styles.responseMsg}>
										<div className={styles.errorMsg}>{formik.status}</div>
									</div>
								)}
								<form className={clsx(styles.myForm)} onSubmit={formik.handleSubmit}>
									<fieldset className={styles.fieldset}>
										<div className={clsx(styles.emailField, styles.myFormFields)}>
											<input
												name="usernameOrEmail"
												// value={formik.values.usernameOrEmail}
												placeholder="Email address or Phone number"
												type="text"
												className={clsx(styles.myInputField, styles.emailInputField)}
												// onChange={formik.handleChange('userNameOrEmail')}
												// onBlur={formik.handleBlur('userNameOrEmail')}
												{...formik.getFieldProps('usernameOrEmail')}
											/>
											{formik.touched.usernameOrEmail && formik.errors.usernameOrEmail && (
												<div className={styles.errorMsg}>{formik.errors.usernameOrEmail}</div>
											)}
										</div>
										<div className={clsx(styles.pwdField, styles.myFormFields)}>
											<input
												name="password"
												placeholder="Password"
												// onChange={formik.handleChange('password')}
												// value={formik.values.password}
												// onBlur={formik.handleBlur('password')}
												className={clsx(styles.myInputField, styles.pwdInputField)}
												type="password"
												{...formik.getFieldProps('password')}
											/>
											{formik.touched.password && formik.errors.password && (
												<div className={styles.errorMsg}>{formik.errors.password}</div>
											)}
										</div>
										<div className={styles.actionButtons}>
											<div className={styles.secondary}>
												<NavLink to="/forgot-password">
													<a
														onClick={() => onCloseModal()}
														href="javascript:void(0)"
														className={clsx(
															'forgotPwdLink d-block mt-2 mb-2',
															styles.forgotPwd
														)}
													>
														Lost your password?
													</a>
												</NavLink>
											</div>
											<div className={styles.primary}>
												{!formik.isSubmitting ? (
													<button
														className={clsx(styles.primaryBtn, styles.signIn)}
														name="sendBtn"
														type="submit"
														disabled={formik.isSubmitting}
													>
														Sign In
													</button>
												) : (
													<div className="spinner-border" role="status">
														<span className="visually-hidden">Loading...</span>
													</div>
												)}
											</div>
										</div>
									</fieldset>
								</form>
								<div className={clsx(styles.formDivider, 'd-none')}>
									<h5 className={styles.dividerContent}>OR</h5>
								</div>
								<div className={clsx(styles.socialLogin, 'd-none')}>
									<ul className={styles.blockContent}>
										<li
											className={clsx(
												styles.blockContentList,
												'actions-toolbar social-btn facebook-login'
											)}
										>
											<a className={clsx(styles.socialLoginLink, 'btn btn-block')}>
												<i className={clsx(styles.fontAwesome, 'bi bi-facebook')} />
											</a>
										</li>
										<li
											className={clsx(
												styles.blockContentList,
												'actions-toolbar social-btn google-login'
											)}
										>
											<a className={clsx(styles.socialLoginLink, 'btn btn-block')}>
												<i className={clsx(styles.fontAwesome, 'bi bi-google')} />
											</a>
										</li>
									</ul>
								</div>

								<div className={styles.formDivider}>
									<h5 className={styles.dividerContent}>OR</h5>
								</div>
								<div className={styles.primary}>
									<button className={styles.primaryBtn} name="sendBtn" onClick={() => onSignUp()}>
										Sign Up
									</button>
								</div>
								<div className={clsx(styles.primary,'d-none')}>
									<button className={styles.primaryBtn} name="sendBtn" onClick={() => onCloseModal()}>
										Continue As Guest
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
