import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AUTH_MODAL from 'utils/constants/authModalConst';
import useScrollPosition from 'hooks/useScrollPosition';
import clsx from 'clsx';
import Toast from 'components/toast/Toast';
import Backdrop from 'components/backdrop/Backdrop';
import { getCartItemsApi } from 'store/shopping/shoppingApi';
import { isEmpty } from 'utils/common';
import AppMobHeader from './app-header/AppMobHeader';
import AppHeader from './app-header/AppHeader';
import AppFooter from './app-footer/AppFooter';
import LoginModal from './components/login-modal/LoginModal';
import SignUpModal from './components/login-modal/SignUpModal';
import LayoutContext from 'context/LayoutContext';
import styles from './AppLayout.module.scss';

function AppLayout(props) {
	const { children } = props;
	const [isScrolled, setScrolled] = useState(false);
	const [headerConfig, setHeaderConfig] = useState({ showTransparent: false });
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isEmpty(localStorage.getItem('cartId'))) {
			dispatch(getCartItemsApi({ cartId: localStorage.getItem('cartId') }));
		}
		else if (!isEmpty(localStorage.getItem("loginId"))) {
			dispatch(getCartItemsApi({ cartId: '' }));

		}
	}, [dispatch]);

	useScrollPosition(
		({ prevPos, currPos }) => {
			console.log(`Curr Y position is: ${currPos.y}`);
			const isShow = Math.abs(currPos.y) > 150;
			console.log(`Curr Y position is: ${Math.abs(currPos.y)}`);
			console.log(`isShow: ${isShow}`);
			console.log(`isScrolled: ${isScrolled}`);
			if (isShow !== isScrolled) {
				setScrolled(isShow);
			}


		},
		[isScrolled],
		null,
		false,
		300
	);
	const { showAuthModal, alertMsg = {}, showBackdrop } = useSelector(state => state.common);
	return (
		<>
			<LayoutContext.Provider value={{ headerConfig, setHeaderConfig }}>
				<AppHeader isScrolled={isScrolled} headerConfig={headerConfig} />
				<AppMobHeader />
				{showAuthModal === AUTH_MODAL.LOGIN && <LoginModal />}
				{showAuthModal === AUTH_MODAL.SIGNUP && <SignUpModal />}
				<div
					className={clsx({
						[styles.mainContainerMargin]: window.location.pathname !== '/',
						[styles.scrolledLayoutContainer]: window.location.pathname !== '/' && isScrolled
					})}
				>
					{children}
				</div>
				<AppFooter />
				<Toast alertMsg={alertMsg} />
				<Backdrop isShow={showBackdrop} />
			</LayoutContext.Provider>
		</>
	);
}
export default AppLayout;
