import axios from 'utils/axios';
import { createRequestObj, createRequest01Obj,createAuthRequestObj, createRequestObjWithoutStoreId, createShoppingRequestObj, createRequestOrder } from 'utils/fingerPrint';

const API_URL = window?.env?.API_URL;

const addToCartUrl = '/store/web/addToCart';
const viewCartUrl = '/store/web/viewCart';
const removeFromCartUrl = '/store/removeFromCart';
const customer_address = '/store/addCustomerAddress';
const confirm_inventory = '/store/confirmInventory';
const place_order = '/store/placeOrder';
const intiate_payment = '/payments/initiatePayment';
const add_wishlist = '/store/addToWishlist';
const getwishlistProducts = '/store/getCustomerWishlistProducts';
const productsById = '/store/web/getProductById';
const mapAddress = '/store/mapAddressToCart';
const store_couponCode = "/store/addCouponCodeToCart";
const remove_wishlistItem = "/store/removeFromWishlist";

const add_message_to_cart = '/store/addMessageToCart';
const store_removecouponCode = "/store/removeCouponCodeFromCart";
export const get_user_address_list = "/store/getUserAddressList";
export const remove_user_address = "/store/removeUserAddress";
export const get_customer_orders = "/store/getOrderSummary";
export const clearCartUrl = '/store/web/clearCart';

const OrderDetails = '/store/getOrderDetails';


export function addToCart(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${addToCartUrl}`,

		data: createRequestObj(params)
	});
}

export function removeFromCart(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${removeFromCartUrl}`,
		data: createRequestObj(params)
	});
}

export function getCartItems(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${viewCartUrl}`,

		data: createRequestObj(params)
	});
}
export function clearCart(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${clearCartUrl}`,
		data: createRequestObj(params)
	});
}

export function addCustomerAddress(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${customer_address}`,
		data: createRequestObj(params)
	});
}


export function confirmInventory(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${confirm_inventory}`,
		// headers: {
		// 	'Content-Type': 'application/json',
		// },
		params: { cartId: localStorage.getItem('cartId') }
	})
}
export function placeOrder(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${place_order}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: createRequestOrder(params)
	});
}

export function initiateCartPayment(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${intiate_payment}`,
		data: createRequest01Obj(params)
	});
}
export function addToWishlist(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${add_wishlist}`,
		data: createRequestObj(params)
	});
}



export function removeFromWishlist(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${remove_wishlistItem}`,
		data: createRequestObj(params)
	});
}

export function getCustomerWishlistProducts(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${getwishlistProducts}`,
		data: createRequestObj(params)
	});
}



export function getCustomerOrdersApi(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${get_customer_orders}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: createRequestObj(params)
	});
}
export function getProductsById(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${productsById}` + `${'?productId='}` + `${params.productId}` + `${'&storeId='}` + `${window.env.STORE_ID}`,

		// data: createRequestObj(params)
	});
}
export function mapAddressToCart(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${mapAddress}`,
		headers: {
			'Content-Type': 'application/json',
		},
		params: params
	});
}

export function getOrderDetailsById(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${OrderDetails}`,
		data: createRequestObj(params)
	});
}




export function addCouponCodeToCart(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${store_couponCode}`,
		data: createRequestObj(params)
	});
}

export function addMessageToCart(params) {
	return axios.request({
		method: 'post',
		// url: `${API_URL}${add_message_to_cart}`,
		// params:params
		url: `${API_URL}${add_message_to_cart}` + `${'?customerMessage='}` + `${params.customerMessage}` + `${'&cartId='}` + `${params.cartId}`,

		// data: createAuthRequestObj(params)
	});
}


export function removeCouponCodeFromCart(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${store_removecouponCode}`,
		data: createRequestObj(params)
	});
}

export function getUserAddressList(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${get_user_address_list}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: createRequestObjWithoutStoreId(params)
	});
}
export function removeUserAddressApi(params) {
	let addressId = params.addressId;

	delete params['addressId'];
	return axios.request({
		method: 'post',
		url: `${API_URL}${remove_user_address}` + `${'?addressId='}` + `${addressId}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: createRequestObjWithoutStoreId(params)
	});
}