import clsx from 'clsx';
import React from 'react';
import Loader from 'react-loader-spinner';
import styles from './Backdrop.module.scss';

function Backdrop(props) {

	return (
		<>
			<div className={clsx(styles.backDrop, !props.isShow && 'd-none')}>
				<div className={styles.block}>
					<Loader type="Puff" color="#ca9275" height={80} width={80} timeout={10000} />
				</div>
			</div>
		</>
	);
}

export default Backdrop;
